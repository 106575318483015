import { React } from 'react'
import Layout from '../components/Layout/indexWp'
import SEO from '../components/SEO'
import styled from '@emotion/styled'

const StyledIframe = styled.iframe`
  height: 100%;
  overflow-y:scroll !important;
  -webkit-overflow-scrolling: touch !important;
`

const Scholarship = ({ data }) => {
  return (
    <Layout>
      <SEO title="2022 Joyful Foundation 장학금 | 죠이플 교회" description="2022 Joyful Foundation 장학금" />
          <StyledIframe 
            log
            src="https://wp.joyfulchurch.us/bulletin/?mod=document&pageid=1&uid=323" 
            style={{ width: '100%', minWidth: '100%'}}
          />
    </Layout>
  )
}

export default Scholarship
